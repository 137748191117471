// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"src/providers/channel/provider.ts"
);
import.meta.hot.lastModified = "1733087018000";
}
// REMIX HMR END

import { ChannelFragmentFragment } from '~storefront/generated/graphql'
import { QueryOptions, sdk } from '~storefront/graphqlWrapper'

export function activeChannel(options: QueryOptions) {
  return sdk
    .activeChannel(undefined, options)
    .then(({ activeChannel }) => activeChannel as ChannelFragmentFragment)
}
